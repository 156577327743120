import React from 'react'
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import LayoutStatic from '../../../components/LayoutStatic';

const style = {
  card: {
    width: '18rem',
    margin: 'auto',
    marginTop: '20px',
  },
  button: {
    marginTop: '10px',
    marginRight: '10px',
    width: '100%'
  }
}

const MentalPage = () => {
  return (
    <LayoutStatic>
      <Helmet>
        <title>แบบประเมินสุขภาพจิต - MorOnline</title>
      </Helmet>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6}>
          <div className="card w-100 mt-1">
            <img src="/img/Mental.jpg" className="card-img-top bootstrap-card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">ประเมินสุขภาพจิต</h5>
              <p className="card-text">สุขภาพจิตของคนไทยขณะนี้น่าเป็นห่วง เพราะปัญหารุมเร้า เศรษฐกิจ โรคระบาด อย่าปล่อยให้มีผลกับคุณโดยไม่รู้ตัว ประเมิน ฟรี!!</p>
              {/* <p className="card-text" style={{ color: 'red' }}>(กำลังอยู่ในขั้นตอนการพัฒนา)</p> */}
              <Link to="/questionnaire?questionnaireId=49" className="btn btn-primary" style={style.button}>แบบวัดความเครียด กรมสุขภาพจิต</Link>
              <Link to="/questionnaire?questionnaireId=50" className="btn btn-primary" style={style.button}>แบบคัดกรองโรคแพนิค(Panic) หรือโรคตื่นตระหนก</Link>
              <Link to="/questionnaire?questionnaireId=51" className="btn btn-primary" style={style.button}>แบบประเมินความเสี่ยงการฆ่าตัวตาย 8 คำถาม (8Q)</Link>
              <Link to="/questionnaire?questionnaireId=52" className="btn btn-primary" style={style.button}>แบบวัดสุขภาพจิต ฉบับสั้น (TMHI-15)</Link>
              <Link to="/questionnaire?questionnaireId=53" className="btn btn-primary" style={style.button}>แบบคัดกรอง โรคอารมณ์สองขั้ว(Bipolar)</Link>
              <Link to="/questionnaire?questionnaireId=42" className="btn btn-primary" style={style.button}>ประเมินพฤติกรรมสุขภาพในชีวิตประจำวัน</Link>
              <Link to="/questionnaire?questionnaireId=43" className="btn btn-primary" style={style.button}>แบบประเมินภาวะซึมเศร้า 9 คำถาม</Link>
              {/* <Link to="/questionnaire?questionnaireId=44" className="btn btn-primary" style={style.button}>แบบคัดดรองภาวะกลืนลำบากในผู้สูงอายุ</Link>
          <Link to="/questionnaire?questionnaireId=45" className="btn btn-primary" style={style.button}>แบบคัดกรองสุขภาวะทางตา</Link>
          <Link to="/questionnaire?questionnaireId=46" className="btn btn-primary" style={style.button}>แบบคัดกรองภาวะการรู้คิดในผู้สูงอายุ</Link>
          <Link to="/questionnaire?questionnaireId=48" className="btn btn-primary" style={style.button}>คัดกรองจำแนกระดับโรคความดันโลหิตสูง</Link> */}

              {/* <Link to="#" className="btn btn-primary" style={style.button}>ตรวจความเสี่ยง อัลไซม์เมอร์</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>กระดูก และ ข้อ</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>เบาหวาน</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>โรค พาคินสัน</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>โรค ไทรอยด์</Link> */}
              <p></p>
              {/* <p className="card-text" style={{ color: 'blue' }}>(แบบคัดกรองอื่นๆ..กำลังพัฒนา)</p> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </LayoutStatic>
  );
}

export default MentalPage;

